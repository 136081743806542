import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Timer from "../Timer";

function Game(props) {
  const { game, bitcoin, fetchGame, socket } = props.data;
  const [gameData, setGameData] = useState(game);
  const [potAmount, setPotAmount] = useState(gameData.pot_amount);
  const [bids, setBids] = useState(gameData.bids);

  useEffect(() => {
    socket.emit("join_game", gameData.slug);
  }, []);

  useEffect(() => {
    socket.on("updatePotAmount", (data) => {
      if (data.slug === gameData.slug) {
        setPotAmount(data.pot_amount);
        setBids(data.bids);
      }
    });

    socket.on("updateGame", (data) => {
      if (data.slug === gameData.slug) {
        setGameData(data);
        setPotAmount(data.pot_amount);
        setBids(data.bids);
      }
    });
  }, [socket]);

  const btcVal = (amt) => {
    var newBtc = amt / bitcoin;
    return newBtc > 0 ? newBtc.toFixed(5) : 0;
  };

  return (
    <div className="col-md-6">
      <div className="card card-body round black gameCard px-xl-3">
        <div className="row my-auto">
          <div className="col-6 mb-3 text-left">
            <h4 className={`mb-0`}>{gameData.name}</h4>
            <h5 className={`mb-0 text-mute`}>#{gameData.gid}</h5>
          </div>
          <div className="col-6 mb-3 text-right">
            <Timer data={{ gameData, fetchGame, socket }} />
            <h5 className={`mb-0 text-mute`}>{bids} Bids</h5>
          </div>
          <div className="col-6 ">
            <h5 className={`mb-0`}>Pot Money(BTC)</h5>
            <h5 className={`mb-0 font-weight-bold`}>${Math.ceil(potAmount)}</h5>
            <h2 className={`mb-0 font-weight-bold`}>₿{btcVal(potAmount)}</h2>
          </div>
          <div className="col-6 text-right mt-auto">
            <Link to={`/games/${game.slug}`} className={`btn round font-medium-3 text-white`} style={{ backgroundColor: gameData.colour, lineHeight:"2.5" }}>Game Info</Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Game;
