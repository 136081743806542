import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Pagination from "../../components/page/Pagination";

function Users(props) {
  const { token, logout } = props.data;
  const [users, setUsers] = useState("");
  const [status, setStatus] = useState("");
  const [search, setSearch] = useState("");
  const [email, setEmail] = useState("");
  const [pagination, setPagination] = useState("");

  const fetchUser = async (pageId) => {
    let response = await fetch(`${process.env.REACT_APP_API_HOST}/api/admin/user?page=${pageId}&email=${email}&status=${status}&search=${search}`, {
      method: "Get",
      headers: { "Content-Type": "application/json", "auth-token": token },
    }).catch((error) => console.log(error));

    if (response.status === 401) {
      logout();
    } else {
      const json = await response.json();
      if (json.status === "success") {
        setUsers(json.data.users);
        setPagination(json.data.pagination);
      }
    }
  };

  useEffect(() => {
    fetchUser(1);
    // eslint-disable-next-line
  }, []);

  return (
    <div className="app-content container center-layout mt-3">
      <div className="content-wrapper">
        <div className="content-body">
          <section id="user-accounts">
            <div className="card round">
              <div className="card-body today-bid">
                <h3 className="font-large-1">Users List</h3>
                <div className="row font-medium-3 font-weight-bold">
                  <div className="col-12 col-sm-6 col-lg-3 mb-2 mb-lg-0">
                    <input type="text" className="form-control font-medium-2 round" placeholder="Enter Name" value={search} onChange={(e) => {setSearch(e.target.value)}} />
                  </div>
                  <div className="col-12 col-sm-6 col-lg-3 mb-2 mb-lg-0">
                    <input type="email" className="form-control font-medium-2 round" placeholder="Enter Email" value={email} onChange={(e) => {setEmail(e.target.value)}} />
                  </div>
                  <div className="col-12 col-sm-6 col-lg-3 mb-2 mb-sm-0">
                    <select className="form-control font-medium-2 round" id="users-list-status" value={status} onChange={(e) => {setStatus(e.target.value)}}>
                      <option value="">Select Status</option>
                      <option value={1}>Active</option>
                      <option value={0}>Inactive</option>
                    </select>
                  </div>
                  <div className="col-12 col-sm-6 col-lg-3">
                    <button className="btn btn-info font-medium-2 round" onClick={() => fetchUser(1)}><i className="la la-search"></i> Search</button>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div className="card round card-body mb-1">
              {users.length > 0 && (
                <div className="table-responsive py-2">
                  <table className="table table-de table-hover mb-0 font-medium-2 black font-weight-bold">
                    <thead>
                      <tr className="font-weight-bold black">
                        <th>Name</th>
                        <th>Email</th>                        
                        <th>Wallet</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {users.map((user) => {
                          return (
                            <tr style={{ alignItems: "center" }} key={user._id}>
                              <td style={{ verticalAlign: "middle" }}>
                                <h5>{user.name}</h5>
                              </td>
                              <td style={{ verticalAlign: "middle" }}>
                                <h5>{user.email}</h5>
                              </td>
                              <td style={{ verticalAlign: "middle" }}>
                                <h5>${user.wallet}</h5>
                              </td>
                              <td>{user.status ? <h5 className="round badge badge-primary">Active</h5> : <h5 className="badge badge-warning">Inactive</h5>}</td>
                              <td>
                                <Link to={`/users/profile/${user._id}`} className="primary mr-1" title="User Info"><i className="la la-user font-large-1"></i></Link>
                                <Link to={`/users/wallet/${user._id}`} className="info mr-1" title="User Wallet Info"><i className="icon-wallet font-large-1"></i></Link>
                                <Link to={`/users/bids/${user._id}`} className="success" title="User Bids Info"><i className="la la-gamepad font-large-1"></i></Link>
                              </td>
                            </tr>
                          );
                        })
                      }
                    </tbody>
                  </table>                
                </div>
              )}
              {!users.length && (
                <h4 className="mb-0 font-larage-1 text-center">There is no data available !</h4>
              )}
            </div>
            <div className="mb-3 mt-2">
              <Pagination data={{ pagination, fetchTran: fetchUser }} />
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default Users;
