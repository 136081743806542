import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Pagination from "../../components/page/Pagination";
import flatpickr from "flatpickr";
import 'flatpickr/dist/flatpickr.css';

function Game(props) {
  const { token, logout } = props.data;
  const [games, setGames] = useState({});
  const [pagination, setPagination] = useState("");
  const [slug, setSlug] = useState("");
  const [date, setDate] = useState("");
  const [search, setSearch] = useState("");
  
  flatpickr("#game-date", {
    onChange: (selectedDates, dateStr, instance) => {
      setDate(dateStr)
    },
  });

  const fetchGame = async (pageId) => {
    let response = await fetch(
      `${process.env.REACT_APP_API_HOST}/api/admin/game?page=${pageId}&slug=${slug}&search=${search}&date=${date}`,
      {
        method: "Get",
        headers: { "Content-Type": "application/json", "auth-token": token },
      }
    ).catch((error) => console.log(error));

    if (response.status === 401) {
      logout();
    } else {
      const json = await response.json();
      if (json.status === "success") {
        setGames(json.data.game);
        setPagination(json.data.pagination);
      }
    }
  };

  const timeFormat = (date) => {
    let newDate = new Date(date);
    return newDate.toLocaleTimeString("en-US", { hour12: false });
  };

  const dateFormat = (date) => {
    let newDate = new Date(date);
    return newDate.toLocaleDateString('en-US', {year: 'numeric', month: '2-digit', day: '2-digit'});
  };

  useEffect(() => {
    fetchGame(1);
    // eslint-disable-next-line
  }, []);

  return (
    <div className="app-content container center-layout mt-3">
      <div className="content-wrapper">
        <div className="content-body">
          <section id="page-account-settings">
            <div className="card round">
              <div className="card-body today-bid">
                <h3 className="font-large-1">Games List</h3>
                <div className="row font-medium-3 font-weight-bold">
                  <div className="col-12 col-sm-6 col-lg-3 mb-2 mb-lg-0">
                    <select className="form-control font-medium-2 round" id="game-slugs" value={slug} onChange={(e) => setSlug(e.target.value)}>
                      <option value="">All Game</option>
                      <option value="three_mins">3 Mins</option>
                      <option value="five_mins">5 Mins</option>
                      <option value="fifteen_mins">15 Mins</option>
                      <option value="six_hours">6 Hours</option>
                    </select>
                  </div>
                  <div className="col-12 col-sm-6 col-lg-3 mb-2 mb-lg-0">
                    <input type="text" className="form-control round flatpickr font-medium-2" id="game-date" placeholder="Select Date"/>
                  </div>
                  <div className="col-12 col-sm-6 col-lg-3 mb-2 mb-sm-0">
                    <input type="text" className="form-control round font-medium-2" placeholder="Enter Game Id" value={search} onChange={(e) => setSearch(e.target.value)} />
                  </div>
                  <div className="col-12 col-sm-6 col-lg-3">
                    <button className="btn btn-info font-medium-2 round" onClick={() => fetchGame(1)}><i className="la la-search font-medium-2"></i> Search</button>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="page-account-settings">
            {games.length > 0 &&
              games.map((game) => {
                return (
                  <div className="card round card-body mb-1" key={game._id}>
                    <div className="tableCard">
                      <div className="px-1 mb-1 mb-md-0">
                        <h4 className="font-weight-bold">Name</h4>
                        <h5 className="mb-0">{game.name}</h5>
                        <h5 className="mb-0">#{game.gid}</h5>
                      </div>
                      <div className="px-1 mb-1 mb-md-0">
                        <h4 className="font-weight-bold">Date/Time</h4>
                        <h5 className="mb-0">{dateFormat(game.start_time)}</h5>
                        <h5 className="mb-0">{timeFormat(game.start_time)}</h5>
                      </div>
                      <div className="px-1 mb-1 mb-md-0">
                        <h4 className="font-weight-bold">End/Draw</h4>
                        <h5 className="mb-0">{timeFormat(game.end_time)}</h5>
                        <h5 className="mb-0">{timeFormat(game.draw_time)}</h5>
                      </div>
                      <div className="px-1 mb-1 mb-md-0">
                        <h4 className="font-weight-bold">Pot Amt</h4>
                        <h5 className="mb-0">${game.pot_amount}</h5>
                        <h5 className="mb-0">{game.bids} bids</h5>
                      </div>
                      <div className="px-1 mb-1 mb-md-0">
                        <h4 className="font-weight-bold">BTC Price</h4>
                        <h5 className="mb-0">${game.start_btc_price}</h5>
                        <h5 className="mb-0">${game.end_btc_price}</h5>
                      </div>
                      <div className="px-1 mb-1 mb-md-0">
                        <h4 className="font-weight-bold">Status</h4>
                        <h5 className={`round badge ${game.status === "pending" ? "badge-primary" : "badge-success"} font-medium-2 ucfirst`}>{game.status}</h5>
                      </div>
                      <div className="px-1">
                        <h4 className="font-weight-bold">Action</h4>
                        <Link to={`/games/${game.slug}?id=${game.gid}`} className="btn btn-sm round btn-info font-medium-1">More info</Link>
                      </div>                  
                    </div>
                  </div>
                );
              })
            }
            {!games.length && 
              <div className="card card-body round mb-1">
                <h4 className="mb-0 font-larage-1 text-center">There is no data available !</h4>
              </div>
            }
            <div className="mb-3 mt-2">
              <Pagination data={{ pagination, fetchTran: fetchGame }} />
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default Game;
