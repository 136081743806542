import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Swal from 'sweetalert2';

function Profile(props) {
  const { token, logout } = props.data;
  const { id } = useParams();
  const [user, setUser] = useState({});
  const [bids, setBids] = useState(0);
  const [earning, setEarning] = useState(0);
  let Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 4000
  });

  const fetchUser = async () => {
    let response = await fetch(`${process.env.REACT_APP_API_HOST}/api/admin/user/${id}`, {
      method: "Get",
      headers: { "Content-Type": "application/json", "auth-token": token },
    }).catch((error) => console.log(error));

    if (response.status === 401) {
      logout();
    } else {
      const json = await response.json();
      if (json.status === "success") {
        setUser(json.data.user);
        setBids(json.data.bids);
        setEarning(json.data.earning);
      }
    }
  };

  useEffect(() => {
    fetchUser();
    // eslint-disable-next-line
  }, []);

  const updateUser = async () => {
    console.log('hi snssn');
    let response = await fetch(`${process.env.REACT_APP_API_HOST}/api/admin/user/update/${id}`, {
      method: "PUT",
      headers: { "Content-Type": "application/json", "auth-token": token },
      body:JSON.stringify(user)
    }).catch((error) => console.log(error));

    if (response.status === 401) {
      logout();
    } else {
      const json = await response.json();
      if (json.status === "success") {
        Toast.fire({
          icon: 'success',
          title: "User details updated successfully",
        });        
      }
    }
  }
  
  const handleChange = (e, field) =>{
    const newArr = {...user};
    newArr[field] = field === 'status' ? parseInt(e.target.value) : e.target.value ;
    setUser(newArr);
  }

  return (
    <div className="app-content container center-layout mt-3">
      <div className="content-wrapper">
        <div className="content-body">
          <section id="users-count">
            <div className="row">
              <div className="col-lg-4 col-md-6 col-12">
                <Link to={`/users/wallet/${id}`}>
                  <div className="card overflow-hidden round">
                    <div className="card-content">
                      <div className="media align-items-stretch">
                        <div className="bg-info p-1 media-middle">
                          <i className="icon-wallet font-large-2 text-white"></i>
                        </div>
                        <div className="media-body p-1">
                          <h4 className="count font-weight-bold">Wallet</h4>
                          <span className="black">more info</span>
                        </div>
                        <div className="media-right my-auto px-1 media-middle">
                          <h1 className="info font-weight-bold mb-0">${user.wallet}</h1>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-lg-4 col-md-6 col-12">
                <Link to={`/users/bids/${id}`}>
                  <div className="card overflow-hidden round">
                    <div className="card-content">
                      <div className="media align-items-stretch">
                        <div className="bg-info p-1 media-middle">
                          <i className="la la-gamepad font-large-2 text-white"></i>
                        </div>
                        <div className="media-body p-1">
                          <h4 className="count font-weight-bold">Bids</h4>
                          <span className="black">more info</span>
                        </div>
                        <div className="media-right my-auto px-1 media-middle">
                          <h1 className="info font-weight-bold mb-0">{bids}</h1>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-lg-4 col-md-6 col-12">
                <Link to={`/users/earning/${id}`}>
                  <div className="card overflow-hidden round">
                    <div className="card-content">
                      <div className="media align-items-stretch">
                        <div className="bg-info p-1 media-middle">
                          <i className="la la-bitcoin font-large-2 text-white"></i>
                        </div>
                        <div className="media-body p-1">
                          <h4 className="count font-weight-bold">Earning</h4>
                          <span className="black">more info</span>
                        </div>
                        <div className="media-right my-auto px-1 media-middle">
                          <h1 className="info font-weight-bold mb-0">${earning}</h1>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </section>
          <section className="mb-3 mt-1">
            <h4 className="gray font-large-1 headingBorder"><b>User Profile</b></h4>
          </section>
          <section id="users-count">
            <div className="card card-body round p-3">
              <div className="row">
                <div className="col-md-4 mb-2 mb-md-0">
                  <img src={`${process.env.REACT_APP_API_HOST}/images/${user.profile}`} className="round img-fluid" alt="profile"/>
                </div>
                <div className="col-md-8 row">
                  <div className="col-12">
                    <div className="form-group">
                      <div className="controls">
                        <label className="labels font-weight-bold font-medium-3" htmlFor="account-name">Name</label>
                        <input type="text" className="form-control font-medium-3" id="account-name" placeholder="Enter Name" value={user.name} onChange={(e) => handleChange(e, "name")} />
                      </div>
                    </div>
                  </div>                  
                  <div className="col-12">
                    <div className="form-group">
                      <div className="controls">
                        <label className="labels font-weight-bold font-medium-3" htmlFor="account-e-mobile">Email Id</label>
                        <input type="text" className="form-control font-medium-3" id="account-e-mobile" placeholder="Enter Email" value={user.email} onChange={(e) => handleChange(e, "email")} required />
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      <label className="labels font-weight-bold font-medium-3">User Status</label>
                      <div className="input-group">
                        <div className="d-inline-block custom-control custom-radio mr-1">
                          <input type="radio" name="customer1" className="custom-control-input skin skin-square" value={1} id="yes" checked={user.status === 1 ? true : false} onChange={(e) => handleChange(e, "status")} />
                          <label className="custom-control-label font-medium-3 font-weight-bold" htmlFor="yes">Active</label>
                        </div>
                        <div className="d-inline-block custom-control custom-radio">
                          <input type="radio" name="customer1" className="custom-control-input skin skin-square" value={0} id="no" checked={user.status === 0 ? true : false} onChange={(e) => handleChange(e, "status")} />
                          <label className="custom-control-label font-medium-3 font-weight-bold" htmlFor="no">Inactive</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 d-flex flex-sm-row flex-column justify-content-start">
                    <button type="button" onClick={() => updateUser()} className="btn btn-info font-medium-3 round">Update Profile</button>                    
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default Profile;
