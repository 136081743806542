import React, { useEffect, useState } from 'react'

function Wallet(props) {
  const { admin, setAdmin, token, logout } = props.data;  
  const [tranInfo, setTranInfo] = useState({});
  const [trans, setTrans] = useState({});
  const [wallet, setWallet] = useState(admin.wallet);

  const fetchTran = async () => {
    let response = await fetch(`${process.env.REACT_APP_API_HOST}/api/admin/wallet/`, {
      method: "Get",
      headers: { "Content-Type": "application/json", "auth-token": token },
    }).catch((error) => console.log(error));

    if (response.status === 401) {
      logout();
    } else {
      const json = await response.json();
      if (json.status === "success") {
        setWallet(json.data.admin.wallet);
        setTrans(json.data.transaction);
      }
    }
  };

  const handleTranInfo = async (tranId) => {
    let response = await fetch(`${process.env.REACT_APP_API_HOST}/api/admin/transaction/${tranId}`, {
      method: "Get",
      headers: { "Content-Type": "application/json", "auth-token": token },
    }).catch((error) => console.log(error));

    if (response.status === 401) {
      props.auth();
    } else {
      const json = await response.json();
      if (json.status === "success") {
        setTranInfo(json.data.transaction);
      }
    }
  };

  const dateFormat = (date) => {
    let newDate = new Date(date);
    return newDate.toLocaleDateString('en-US', {year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', hour12: false, minute:'2-digit', second:'2-digit'});
  };

  useEffect(() => {
    fetchTran();    
    // eslint-disable-next-line
  },[]);

  return (
    <div className="app-content container center-layout mt-3">
      <div className="content-wrapper">
        <div className="content-body">         
          <section>
            <div className="row">
              <div className="col-xl-4 col-md-12">
                <div className="card round">
                  <div className="card-content">
                    <div className="media align-items-stretch text-white round bg-wallet">
                      <div className="bg-dark-wallet p-1 round-wallet my-auto">
                        <i className="icon-wallet font-large-2 text-white"></i>
                      </div>
                      <div className="media-body p-1 my-auto">
                        <h2 className="text-white font-weight-bold">Available Balance</h2>
                        <h3 className="text-white font-weight-bold">${wallet}</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="page-account-settings">
            <h4 className="gray font-large-1 headingBorder mb-3"><b>Transaction List</b></h4>
            <div className="card">             
              <div className="card-body today-bid">                
                <div className="table-responsive">
                  <table className="table table-de table-hover mb-0 black">
                    <thead>
                      <tr className="font-weight-bold black">
                        <th>Date</th>
                        <th>Status</th>
                        <th>Amount</th>
                        <th>Type</th>
                        <th>More info</th>
                      </tr>
                    </thead>
                    <tbody>
                      {trans.length > 0 &&
                        trans.map((tran) => {
                          return (
                            <tr style={{ alignItems: "center" }} key={tran._id}>
                              <td>
                                <h5>{dateFormat(tran.created_at)}</h5>
                              </td>
                              <td>
                                <h5 className={`badge badge-${tran.status === 'success' ? 'success' : 'danger'} font-medium-2 ucfirst`}>{tran.status}</h5>
                              </td>
                              <td>
                                <h5 className={`${tran.type == 'commission' || tran.type == 'Add' ? 'success' : 'danger' }`}>${tran.amount}</h5>
                              </td>
                              <td>
                                <h5 className={`${tran.type == 'commission' || tran.type == 'Add' ? 'success' : 'danger' } ucfirst`}>{tran.type}</h5>
                              </td>
                              <td style={{ verticalAlign: "middle" }}>
                                <button className="btn btn-sm round btn-outline-primary font-medium-1" onClick={() => handleTranInfo(tran._id)} data-toggle="modal" data-target="#bidInfoModal">
                                  More info
                                </button>
                              </td>
                            </tr>
                          );
                        })
                      }
                    </tbody>
                  </table>
                  {/* <Pagination data={{pagination, fetchTran}}/> */}
                </div>
              </div>
            </div>
          </section>
          <div className="modal animated zoomIn" id="bidInfoModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title font-medium-3 black font-weight-bold">Transaction Info</h5>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">
                      <i className="fa-regular fa-rectangle-xmark"></i>
                    </span>
                  </button>
                </div>
                <div className="modal-body">
                  <table className="table table-bordered font-medium-1 font-weight-bold black">
                    <tbody>
                      <tr>
                        <td>Date Time</td>
                        <td>{dateFormat(tranInfo.created_at)}</td>
                      </tr>
                      <tr>
                        <td>Tran Id</td>
                        <td>{tranInfo.tran_id}</td>
                      </tr>
                      <tr>
                        <td>Status</td>
                        <td className="ucfirst">{tranInfo.status}</td>
                      </tr>
                      <tr>
                        <td>Amount</td>
                        <td>{tranInfo.amount}</td>
                      </tr>
                      <tr>
                        <td>Type</td>
                        <td className="ucfirst">{tranInfo.type}</td>
                      </tr>
                      {
                        tranInfo.bid_id && 
                        <tr>
                          <td>Bid Id</td>
                          <td>{tranInfo.bid_id}</td>
                        </tr>
                      }
                      <tr>
                        <td>Note</td>
                        <td>{tranInfo.note}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Wallet
