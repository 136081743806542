import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Game from "../components/page/Game";
import Pagination from "../components/page/Pagination";

function Index(props) {
  const { socket, admin, logout } = props.data;
  const token = localStorage.getItem("admin-token");
  const [games, setGames] = useState({});
  const [gameList, setGameList] = useState({});
  const [bitcoin, setBitcoin] = useState(0);
  const [oldBtc, setOldBtc] = useState(0);
  const [count, setCount] = useState("");
  const [coin, setCoin] = useState(0);
  const [pagination, setPagination] = useState("");

  const formatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  const fetchGame = async (pageId) => {
    let response = await fetch(`${process.env.REACT_APP_API_HOST}/api/admin?page=${pageId}`, {
      method: "Get",
      headers: { "Content-Type": "application/json", "auth-token": token },
    }).catch((error) => console.log(error));

    if (response.status === 401) {
      logout();
    } else {
      const json = await response.json();
      if (json.status === "success") {
        setGames(json.data.games);
        setCount(json.data.count);
        setGameList(json.data.gameLists);
        setPagination(json.data.pagination);
      }
    }
  };

  useEffect(() => {
    fetchGame(1);
    // eslint-disable-next-line
    const tradeWs = new WebSocket("wss://ws.coincap.io/prices?assets=bitcoin");
    tradeWs.onmessage = function (msg) {
      var data = JSON.parse(msg.data);
      setCoin(data.bitcoin);
      setBitcoin(data.bitcoin);
    };
  }, []);

  const timeFormat = (date) => {
    let newDate = new Date(date);
    return newDate.toLocaleTimeString("en-US", { hour12: false });
  };

  const dateFormat = (date) => {
    let newDate = new Date(date);
    return newDate.toLocaleDateString("en-US", { year: "numeric", month: "2-digit", day: "2-digit" });
  };

  useEffect(() =>{
    let btcPrice = document.getElementById('btcprice');
    btcPrice.style.color = oldBtc > coin ? "#ff4961" : "#28d094";
    setOldBtc(coin);
  },[coin]);

  return (
    <div className="app-content container center-layout mt-3">
      <div className="content-wrapper">
        <div className="content-body">
          <section id="stats-icon-subtitle">
            <div className="row">
              <div className="col-lg-4 col-md-6 col-12">
                <Link to={"/users"}>
                  <div className="card overflow-hidden round">
                    <div className="card-content">
                      <div className="media align-items-stretch">
                        <div className="bg-info p-1 media-middle">
                          <i className="la la-users font-large-2 text-white"></i>
                        </div>
                        <div className="media-body p-1">
                          <h4 className="count font-weight-bold">Users</h4>
                          <span className="black">Joined this month</span>
                        </div>
                        <div className="media-right my-auto px-1 media-middle">
                          <h1 className="info font-weight-bold mb-0">{count.users}</h1>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-lg-4 col-md-6 col-12">
                <Link to={"/games"}>
                  <div className="card overflow-hidden round">
                    <div className="card-content">
                      <div className="media align-items-stretch">
                        <div className="bg-info p-1 media-middle">
                          <i className="la la-gamepad font-large-2 text-white"></i>
                        </div>
                        <div className="media-body p-1">
                          <h4 className="count font-weight-bold">Games</h4>
                          <span className="black">Played this month</span>
                        </div>
                        <div className="media-right my-auto px-1 media-middle">
                          <h1 className="info font-weight-bold mb-0">{count.games}</h1>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-lg-4 col-md-6 col-12">
                <Link to={"/earning"}>
                  <div className="card overflow-hidden round">
                    <div className="card-content">
                      <div className="media align-items-stretch">
                        <div className="bg-info p-1 media-middle">
                          <i className="la la-bitcoin font-large-2 text-white"></i>
                        </div>
                        <div className="media-body p-1">
                          <h4 className="count font-weight-bold">Earnings</h4>
                          <span className="black">Earn this month</span>
                        </div>
                        <div className="media-right my-auto px-1 media-middle">
                          <h1 className="info font-weight-bold mb-0">${count.earning}</h1>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </section>
          <section>
            <div className="row">
              <div className="col-lg-6 mb-2">
                <div className="card-content">
                  <div className="media align-items-stretch bg-light text-white round">
                    <div className="bg-secondary p-2 media-middle round">
                      <i className="la la-btc font-large-2 text-white"></i>
                    </div>
                    <div className="media-body px-2 py-1">
                      <h4 className="font-weight-bold font-large-1 mb-0">Bitcoin</h4>
                      <span className="black font-medium-2">Current Price</span>
                    </div>
                    <div className="media-right p-2 media-middle my-auto">                      
                      <h1 className="font-weight-bold font-large-1 my-auto" id="btcprice">${coin}</h1>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 mb-2">
                <Link to={"/wallet"}>
                  <div className="card-content">
                    <div className="media align-items-stretch text-white round bg-wallet">
                      <div className="p-2 media-middle my-auto">
                        <h1 className="text-white font-weight-bold font-large-1 my-auto">${formatter.format(admin.wallet)}</h1>
                      </div>
                      <div className="media-body px-2 py-1">
                        <h4 className="text-white font-weight-bold font-large-1 mb-0">Wallet</h4>
                        <span className="font-medium-2">Withdraw Money</span>
                      </div>
                      <div className="media-right bg-dark-wallet p-2 media-middle round">
                        <i className="icon-wallet font-large-2 text-white"></i>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </section>
          <section className="mb-3 mt-1">
            <h4 className="gray font-large-1 headingBorder"><b>Running Games</b></h4>
          </section>
          <section>
            <div className="row mt-2 mb-1">
              {games.length > 0 &&
                games.map((game) => {
                  return <Game key={game.gid} data={{ socket, game, bitcoin, fetchGame }} />;
                })}
            </div>
          </section>
          <section className="mb-3 today-bid">
            <h4 className="gray font-large-1 headingBorder">
              <b>Played in the last 24 hours</b>
              <Link to={"/games"} className="btn btn-sm round btn-info font-medium-3 ml-1">All Games</Link>
            </h4>
            <div className="my-3">              
              {gameList.length > 0 &&
                gameList.map((game) => {
                  return (
                    <div className="card card-body round mb-1" key={game._id}>
                      <div className="tableCard">
                        <div className="px-1 mb-1 mb-md-0">
                          <h4 className="font-weight-bold">Name</h4>
                          <h5 className="mb-0">{game.name}</h5>
                          <h5 className="mb-0">#{game.gid}</h5>
                        </div>
                        <div className="px-1 mb-1 mb-md-0">
                          <h4 className="font-weight-bold">Date/Time</h4>
                          <h5 className="mb-0">{dateFormat(game.start_time)}</h5>
                          <h5 className="mb-0">{timeFormat(game.start_time)}</h5>
                        </div>
                        <div className="px-1 mb-1 mb-md-0">
                          <h4 className="font-weight-bold">End/Draw</h4>
                          <h5 className="mb-0">{timeFormat(game.end_time)}</h5>
                          <h5 className="mb-0">{timeFormat(game.draw_time)}</h5>
                        </div>
                        <div className="px-1 mb-1 mb-md-0">
                          <h4 className="font-weight-bold">Pot Amt</h4>
                          <h5 className="mb-0">${game.pot_amount}</h5>
                          <h5 className="mb-0">{game.bids} bids</h5>
                        </div>
                        <div className="px-1 mb-1 mb-md-0">
                          <h4 className="font-weight-bold">BTC Price</h4>
                          <h5 className="mb-0">${game.start_btc_price}</h5>
                          <h5 className="mb-0">${game.end_btc_price}</h5>
                        </div>                        
                        <div className="px-1">
                          <h4 className="font-weight-bold">Action</h4>                          
                          <Link to={`/games/${game.slug}?id=${game.gid}`} className="btn btn-sm round btn-info font-medium-1">More info</Link>
                        </div>                  
                      </div>
                    </div>
                  )
                })
              }
              {!gameList.length && 
                <div className="card card-body round mb-1">
                  <h4 className="mb-0 font-larage-1 text-center">There is no data available !</h4>
                </div>
              }
              <div className="mb-3 mt-2">
                <Pagination data={{ pagination, fetchTran: fetchGame }} />
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default Index;
