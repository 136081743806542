import React, { useEffect, useState } from 'react'

function Earning(props) {
  const { token, logout } = props.data;  
  const [tranInfo, setTranInfo] = useState({});
  const [trans, setTrans] = useState({});
  const [earning, setEarning] = useState(0);

  const fetchTran = async () => {
    let response = await fetch(`${process.env.REACT_APP_API_HOST}/api/admin/earning/`, {
      method: "Get",
      headers: { "Content-Type": "application/json", "auth-token": token },
    }).catch((error) => console.log(error));

    if (response.status === 401) {
      logout();
    } else {
      const json = await response.json();
      if (json.status === "success") {
        setEarning(json.data.earning);
        setTrans(json.data.transaction);
      }
    }
  };

  const handleTranInfo = async (tranId) => {
    let response = await fetch(`${process.env.REACT_APP_API_HOST}/api/admin/transaction/${tranId}`, {
      method: "Get",
      headers: { "Content-Type": "application/json", "auth-token": token },
    }).catch((error) => console.log(error));

    if (response.status === 401) {
      props.auth();
    } else {
      const json = await response.json();
      if (json.status === "success") {
        setTranInfo(json.data.transaction);
      }
    }
  };

  const dateFormat = (date) => {
    let newDate = new Date(date);
    return newDate.toLocaleDateString('en-US', {year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', hour12: false, minute:'2-digit', second:'2-digit'});
  };

  useEffect(() => {
    fetchTran();    
    // eslint-disable-next-line
  },[]);

  return (
    <div className="app-content container center-layout mt-3">
      <div className="content-wrapper">
        <div className="content-body">
          <section>
            <div className="row">
              <div className="col-xl-5 col-md-12">
                <div className={`card round card-content`}>
                  <div className="media align-items-stretch text-white round bg-wallet">
                    <div className="media-right bg-dark-wallet p-2 media-middle round my-auto">
                      <i className="icon-wallet font-large-2 text-white"></i>
                    </div>
                    <div className="media-body p-1 my-auto">
                      <h4 className="text-white font-weight-bold font-medium-3">Total Earning</h4>
                      <h2 className="text-white mb-0">${earning ?? 0}</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>           
          <section id="page-account-settings">
            <h4 className="gray font-large-1 headingBorder"><b>Transaction List</b></h4>
            <div className="my-3">
              {trans.length > 0 &&
                trans.map((tran) => {
                  return (
                    <div className="card card-body round mb-1" key={tran._id}>
                      <div className="tableCard">
                        <div className="px-1 mb-1 mb-md-0">
                          <h4 className="font-weight-bold">Date</h4>
                          <h5 className="mb-0">{dateFormat(tran.created_at)}</h5>
                        </div>
                        <div className="px-1 mb-1 mb-md-0">
                          <h4 className="font-weight-bold">Amount</h4>
                          <h5 className={`mb-0 text-center ${tran.type == 'win' || tran.type == 'Add' ? 'success' : 'danger' }`}>${tran.amount}</h5>
                        </div>
                        <div className="px-1 mb-1 mb-md-0">
                          <h4 className="font-weight-bold">Type</h4>
                          <h5 className={`mb-0 text-center ${tran.type == 'win' || tran.type == 'Add' ? 'success' : 'danger' } ucfirst`}>{tran.type}</h5>
                        </div>
                        <div className="px-1 mb-1 mb-md-0">
                          <h4 className="font-weight-bold">Status</h4>
                          <h5 className={`mb-0 round badge badge-${tran.status === 'success' ? 'success' : 'danger'} font-medium-2 ucfirst`}>{tran.status}</h5>
                        </div>
                        <div className="px-1 mb-1 mb-md-0">
                          <h4 className="font-weight-bold">Action</h4>
                          <button className="btn btn-sm round btn-info font-medium-1" onClick={() => handleTranInfo(tran._id)} data-toggle="modal" data-target="#bidInfoModal">More info</button>
                        </div>
                      </div>
                    </div>
                  )
                })
              }
              {!trans.length && 
                <div className="card card-body round mb-1">
                  <h4 className="mb-0 font-larage-1 text-center">There is no data available !</h4>
                </div>
              }
            </div>
          </section>
          <div className="modal animated zoomIn" id="bidInfoModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content round">
                <div className="modal-header">
                  <h5 className="modal-title font-medium-3 font-weight-bold">Transaction Info</h5>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">
                      <i className="fa-regular fa-rectangle-xmark"></i>
                    </span>
                  </button>
                </div>
                <div className="modal-body p-2">
                  <div className="table-responsive">
                    <table className="table mb-0 table-bordered">
                      <tbody className="font-medium-3">
                        <tr>
                          <td><b>Date Time</b></td>
                          <td>{dateFormat(tranInfo.created_at)}</td>
                        </tr>
                        <tr>
                          <td><b>Tran Id</b></td>
                          <td>{tranInfo.tran_id}</td>
                        </tr>
                        <tr>
                          <td><b>Status</b></td>
                          <td><span className={`round badge badge-${tranInfo.status === 'success' ? 'success' : 'danger'} font-medium-2 ucfirst`}>{tranInfo.status}</span></td>
                        </tr>
                        <tr>
                          <td><b>Amount</b></td>
                          <td><span className={`${tranInfo.type === 'win' || tranInfo.type === 'Add' ? 'success' : 'danger' }`}>${tranInfo.amount}</span></td>
                        </tr>
                        <tr>
                          <td><b>Type</b></td>
                          <td className="ucfirst">{tranInfo.type}</td>
                        </tr>
                        {
                          tranInfo.bid_id && 
                          <tr>
                            <td><b>Bid Id</b></td>
                            <td>{tranInfo.bid_id}</td>
                          </tr>
                        }
                        <tr>
                          <td><b>Note</b></td>
                          <td className="ucfirst">{tranInfo.note}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Earning
