import React, { useEffect, useState } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";

function Details(props) {
  const { token, logout } = props.data;
  const { slug } = useParams();
  const [searchParams] = useSearchParams();
  const [game, setGame] = useState("");
  const [bids, setBids] = useState("");
  const [bidInfo, setBidInfo] = useState({});

  const fetchGame = async () => {
    let response = await fetch(
      `${process.env.REACT_APP_API_HOST}/api/admin/game/${slug}${
        searchParams.get("id") != null ? `?id=${searchParams.get("id")}` : ""
      }`,
      {
        method: "Get",
        headers: { "Content-Type": "application/json", "auth-token": token },
      }
    ).catch((error) => console.log(error));

    if (response.status === 401) {
      logout();
    } else {
      const json = await response.json();
      if (json.status === "success") {
        setGame(json.data.game);
        setBids(json.data.bid);
      }
    }
  };

  const handleBidInfo = async (bidId) => {
    let response = await fetch(
      `${process.env.REACT_APP_API_HOST}/api/admin/bid/${bidId}`,
      {
        method: "Get",
        headers: { "Content-Type": "application/json", "auth-token": token },
      }
    ).catch((error) => console.log(error));

    if (response.status === 401) {
      props.auth();
    } else {
      const json = await response.json();
      if (json.status === "success") {
        // json.data.bid.btc_price = JSON.parse(json.data.bid.btc_price);
        setBidInfo(json.data);
      }
    }
  };

  const timeFormat = (date) => {
    let newDate = new Date(date);
    return newDate.toLocaleTimeString("en-US", { hour12: false });
  };

  const dateFormat = (date) => {
    let newDate = new Date(date);
    return newDate.toLocaleDateString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
  };

  const fullDate = (date) => {
    let newDate = new Date(date);
    return newDate.toLocaleDateString("en-US", { year: "numeric", month: "2-digit", day: "2-digit", hour: "2-digit", hour12: false, minute: "2-digit", second: "2-digit" });
  };

  useEffect(() => {
    fetchGame();
    // eslint-disable-next-line
  }, [slug]);

  return (
    <div className="app-content container center-layout mt-3">
      <div className="content-wrapper">
        <div className="content-body">
          <section>
            <h4 className="gray font-large-1 headingBorder"><b>Games Details</b></h4>
            <div className="row mt-3">
              <div className="col-lg-3 col-sm-6">
                <div className="card card-body round">
                  <table className="table-borderless mb-0">
                    <tbody className="font-medium-3">
                      <tr>
                        <td><b>Name</b></td>
                        <td>{game?.name}</td>
                      </tr>
                      <tr>
                        <td><b>Game Id</b></td>
                        <td>{game?.gid}</td>
                      </tr>
                      <tr>
                        <td><b>Status</b></td>
                        <td className="ucfirst">{game?.status}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="card card-body round">
                  <table className="table-borderless mb-0">
                    <tbody className="font-medium-3">
                      <tr>
                        <td><b>Start-time</b></td>
                        <td>{timeFormat(game?.start_time)}</td>
                      </tr>
                      <tr>
                        <td><b>End-time</b></td>
                        <td>{timeFormat(game?.end_time)}</td>
                      </tr>
                      <tr>
                        <td><b>Draw-time</b></td>
                        <td>{timeFormat(game?.draw_time)}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>                
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="card card-body round">
                  <table className="table-borderless mb-0">
                    <tbody className="font-medium-3">
                      <tr>
                        <td><b>Date</b></td>
                        <td>{dateFormat(game?.start_time)}</td>
                      </tr>
                      <tr>
                        <td><b>Bids</b></td>
                        <td>{bids?.length}</td>
                      </tr>
                      <tr>
                        <td><b>Pot Money</b></td>
                        <td>${game?.pot_amount}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="card card-body round">
                  <table className="table-borderless mb-0">
                    <tbody className="font-medium-3">
                      <tr>
                        <td><b>Start Btc</b></td>
                        <td>₿{game?.start_btc_price}</td>
                      </tr>
                      <tr>
                        <td><b>End Btc</b></td>
                        <td>{game?.end_btc_price ? `₿${game?.end_btc_price}`: "NA"}</td>
                      </tr>
                      <tr>
                        <td><b>Win Amount</b></td>
                        <td><span className="badge badge-info font-medium-3 round">{game?.winning_number}</span></td>
                      </tr>
                    </tbody>
                  </table>
                </div>                
              </div>
            </div>
          </section>
          <section>
            <h4 className="gray font-large-1 headingBorder"><b>Games Bids</b></h4>
            <div className="card card-body round my-3 px-2 py-3">
              {bids?.length > 0 && (
                <div className="table-responsive">
                  <table className="table table-de table-hover mb-0 black">
                    <thead>
                      <tr className="font-weight-bold black">
                        <th>User Name</th>
                        <th>Bid Amount</th>
                        <th>Picked No</th>
                        <th>Units</th>
                        {/* <th>Status</th> */}
                        <th>More info</th>
                      </tr>
                    </thead>
                    <tbody>
                      {bids?.length > 0 &&
                        bids.map((bid) => {
                          return (
                            <>
                              <tr style={{ alignItems: "center" }} key={bid._id}>
                                <td style={{ verticalAlign: "middle" }}>
                                  <h5>{bid.user}</h5>
                                </td>
                                <td style={{ verticalAlign: "middle" }}>
                                  <h5>${bid.amount}</h5>
                                </td>
                                <td style={{ verticalAlign: "middle" }}>
                                  <span className="round badge badge-info">{bid?.btc_range}</span>
                                  {/* {JSON.parse(bid.btc_price).length > 0 && JSON.parse(bid.btc_price).map((picked, inx) => {
                                    return <span key={inx+picked} className="round badge badge-info mr-1">{picked}</span>
                                  })} */}
                                  {/* <h5>{bid.btc_price}</h5> */}
                                </td>
                                <td style={{ verticalAlign: "middle" }}>
                                  <h5><span className="badge badge-info round">{bid.unit}</span></h5>
                                </td>
                                {/* <td style={{ verticalAlign: "middle" }}>
                                  {(bid.status === "pending" || bid.status === "running") && <h5 className="round badge badge-primary font-medium-2 ucfirst">{bid.status}</h5>}
                                  {(bid.status === "win" || bid.status === "sorry") && <h5 className={`round badge badge-${bid.status === "win" ? "success" : "warning"} font-medium-2 ucfirst`}>{bid.status}</h5>}                                    
                                </td> */}
                                <td style={{ verticalAlign: "middle" }}>
                                  <button
                                    className="btn btn-sm round btn-info font-medium-1"
                                    onClick={() => handleBidInfo(bid._id)}
                                    data-toggle="modal"
                                    data-target="#bidInfoModal"
                                  >
                                    More info
                                  </button>
                                </td>
                              </tr>
                            </>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              )}
              {!bids?.length && (
                <h4 className="mb-0 font-larage-1 text-center">There is no data available !</h4>
              )}
            </div>
          </section>
        </div>
        <div className="modal animated zoomIn" id="bidInfoModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content round">
              <div className="modal-header">
                <h5 className="modal-title font-medium-3 font-weight-bold">Bid Info</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true"><i className="fa-regular fa-rectangle-xmark"></i></span>
                </button>
              </div>
              <div className="modal-body p-2">
                <div className="table-responsive">
                  <table className="table mb-0 table-bordered">
                    <tbody className="font-medium-3">
                      <tr>
                        <td><b>Date Time</b></td>
                        <td>{fullDate(bidInfo?.bid?.created_at)}</td>
                      </tr>
                      <tr>
                        <td><b>Game Id</b></td>
                        <td>#{bidInfo?.bid?.gid}</td>
                      </tr>
                      <tr>
                        <td><b>Picked Number</b></td>
                        <td>
                          <span className="round badge badge-info">{bidInfo?.bid?.btc_range}</span>
                          {/* {bidInfo?.bid?.btc_price.length > 0 && 
                            bidInfo?.bid?.btc_price.map((picked, inx) => {
                              return <span key={inx+picked} className="round badge badge-info mr-1">{picked}</span>
                            })
                          } */}
                        </td>
                      </tr>
                      <tr>
                        <td><b>Status</b></td>
                        <td className="ucfirst">
                          {(bidInfo?.bid?.status === "pending" || bidInfo?.bid?.status === "running") && <h5 className="mb-0 round badge badge-primary font-medium-2 ucfirst">{bidInfo?.bid?.status}</h5>}
                          {(bidInfo?.bid?.status === "win" || bidInfo?.bid?.status === "sorry") && <h5 className={`mb-0 round badge badge-${bidInfo?.bid?.status === "win" ? "success" : "warning"} font-medium-2 ucfirst`}>{bidInfo?.bid?.status}</h5>}
                        </td>
                      </tr>
                      <tr>
                        <td><b>Winning Number</b></td>
                        <td><span className="badge badge-success font-medium-2 round">{bidInfo?.bid?.status === "win" || bidInfo?.bid?.status === "sorry" ? bidInfo?.game?.winning_number : ''}</span></td>
                      </tr>
                      <tr>
                        <td><b>Winning Amount</b></td>
                        <td><span className={`text-${bidInfo?.bid?.win_amount && 'success'}`}>${bidInfo?.bid?.win_amount}</span></td>
                      </tr>
                      <tr>
                        <td><b>Bid Units</b></td>
                        <td><span className="badge badge-info font-medium-2 round">{bidInfo?.bid?.unit}</span></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Details;
