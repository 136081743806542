import { useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Login from "./pages/auth/Login";
import Index from "./pages/Index";
import Profile from "./pages/Profile";
import Users from "./pages/user/Users";
import UsersProfile from "./pages/user/Profile";
import UsersWallet from "./pages/user/Wallet";
import UsersBids from "./pages/user/Bids";
import UsersEarning from "./pages/user/Earning";
import Game from "./pages/game/Game";
import GameDetail from "./pages/game/Details";
import Earning from "./pages/Earning";
import Wallet from "./pages/Wallet";
import io from "socket.io-client";
import Toster from "./components/Toster";

function App() {
  const socket = io.connect(process.env.REACT_APP_API_HOST);
  const auth = localStorage.getItem("admin-token") ? true : false;
  const [admin, setAdmin] = useState(JSON.parse(localStorage.getItem("admin")));
  const [token, setToken] = useState(localStorage.getItem("admin-token"));
  const [isLogin, setIsLogin] = useState(auth);

  const logout = () => {
    localStorage.clear();
    setIsLogin(false);
  };

  return (
    <>
      {isLogin === true ? (
        <>
          <Header data={{ admin, logout }} />
          <Routes>
            <Route exact path="/" element={<Index data={{ socket, admin, logout }} />} />
            <Route exact path="/profile" element={<Profile data={{ token, update:setAdmin, logout }} />} />
            <Route exact path="/users" element={<Users data={{ token, logout }} />} />
            <Route exact path="/users/profile/:id" element={<UsersProfile data={{ token, logout }} />} />
            <Route exact path="/users/wallet/:id" element={<UsersWallet data={{ token, logout }} />} />
            <Route exact path="/users/bids/:id" element={<UsersBids data={{ token, logout }} />} />
            <Route exact path="/users/earning/:id" element={<UsersEarning data={{ token, logout }} />} />
            <Route exact path="/games" element={<Game data={{ token, logout }} />} />
            <Route exact path="/games/:slug" element={<GameDetail data={{ token, logout }} />} />
            <Route exact path="/earning" element={<Earning data={{ token, logout }} />} />
            <Route exact path="/wallet" element={<Wallet data={{ admin, setAdmin, token, logout }} />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
          {/* <Toster data={{ socket, admin }} /> */}
          <Footer />
        </>
      ) : (
        <>
          <Routes>
            <Route exact path="/login" element={<Login data={{ setAdmin, setToken, setIsLogin }} />} />
            <Route path="*" element={<Navigate to="/login" />} />
          </Routes>
        </>
      )}
    </>
  );
}

export default App;
