import React, { useState } from "react";

function Profile(props) {
  const { token, update, logout } = props.data;
  const [admin, setAdmin] = useState(JSON.parse(localStorage.getItem("admin")));
  const [password, setPassword] = useState({old:'', new:'', confirm:''});

  const handleChange = (e, field) => {
    const newArr = {...admin};
    newArr[field] = e.target.value;
    setAdmin(newArr);
  }

  const handlePassword = (e, field) => {
    const newArr = {...password};
    newArr[field] = e.target.value;
    setPassword(newArr);
  }

  const updateProfile = async () => {    
    let response = await fetch(`${process.env.REACT_APP_API_HOST}/api/admin/update/`, {
      method: "Post",
      headers: { "Content-Type": "application/json", "auth-token": token },
      body:JSON.stringify(admin)
    }).catch((error) => console.log(error));

    if (response.status === 401) {
      logout();
    } else {
      const json = await response.json();
      if (json.status === "success") {
        update(json.data.admin);
        setAdmin(json.data.admin);
        localStorage.setItem("admin", JSON.stringify(json.data.admin));
      }
    }
  };

  return (
    <div className="app-content container center-layout mt-3">
      <div className="content-wrapper">
        <div className="content-body">
          <section id="page-account-settings">
            <div className="row justify-content-center">
              <div className="col-xl-8">
                <h4 className="gray font-large-1 headingBorder mb-3"><b>Profile Info</b></h4>
                <div className="card round">
                  <div className="card-body">
                    <div className="row">                      
                      <div className="col-12">
                        <div className="form-group">
                          <div className="controls">
                            <label className="font-medium-3" htmlFor="account-name">Name</label>
                            <input type="text" className="form-control round font-medium-3" id="account-name" value={admin.name} onChange={(e) => handleChange(e, 'name')} placeholder="Name" required />
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group">
                          <div className="controls">
                            <label className="font-medium-3" htmlFor="account-e-mail">Email</label>
                            <input type="email" className="font-medium-3 form-control round" id="account-e-mail" value={admin.email} onChange={(e) => handleChange(e, 'email')} placeholder="Email" required />
                          </div>
                        </div>
                      </div>
                      <div className="col-12 d-flex flex-sm-row flex-column">
                        <button type="button" className="btn btn-primary round font-medium-3 mr-sm-1 mb-1 mb-sm-0" onClick={() => updateProfile()}>Update</button>
                        <button type="reset" className="btn btn-light round font-medium-3">Cancel</button>
                      </div>
                    </div>
                  </div>
                </div>
                <h4 className="gray font-large-1 headingBorder my-3"><b>Change Admin Password</b></h4>
                <div className="card round mb-3">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-12">
                        <div className="form-group">
                          <div className="controls">
                            <label className="font-medium-3" htmlFor="old-password">Old Password</label>
                            <input type="password" className="form-control round font-medium-3" id="old-password" placeholder="Old Password" value={password.old} onChange={(e) => handlePassword(e, 'old')} />
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group">
                          <div className="controls">
                            <label className="font-medium-3" htmlFor="new-password">New Password</label>
                            <input type="password" className="form-control round font-medium-3" id="new-password" placeholder="New Password" value={password.new} onChange={(e) => handlePassword(e, 'new')} />
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group">
                          <div className="controls">
                            <label className="font-medium-3" htmlFor="confirm-password">Confirm Password</label>
                            <input type="password" className="form-control round font-medium-3" id="confirm-password" placeholder="Confirm Password" value={password.confirm} onChange={(e) => handlePassword(e, 'confirm')} />
                          </div>
                        </div>
                      </div>
                      <div className="col-12 d-flex flex-sm-row flex-column justify-content-start">
                        <button type="button" className="btn font-medium-3 btn-primary mr-sm-1 mb-1 mb-sm-0 round">Update Password</button>
                        <button type="reset" className="btn font-medium-3 btn-light round">Cancel</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>              
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default Profile;
