import React from "react";

function Pagination(props) {
  const { pagination, fetchTran } = props.data;
  const { page, pages } = pagination;
  let middlePagination;

  if (pages <= 5) {
    middlePagination = [...Array(pages)].map((_, idx) => (
      <li className={`page-item ${page === idx + 1 ? "active" : ""}`} key={idx + 1}>
        <button className="page-link" onClick={() => fetchTran(idx + 1)} disabled={page === idx + 1}>
          {idx + 1}
        </button>
      </li>
    ));
  } else {
    const startValue = Math.floor((page - 1) / 5) * 5;
    middlePagination = (
      <>
        {[...Array(5)].map((_, idx) => (
          <li className={`page-item ${page === startValue + idx + 1 ? "active" : ""}`} key={startValue + idx + 1}>
            <button className="page-link" onClick={() => fetchTran(startValue + idx + 1)} disabled={page === startValue + idx + 1}>
              {startValue + idx + 1}
            </button>
          </li>
        ))}
        <li className={`page-item`}>
          <button className="page-link" disabled>
            ...
          </button>
        </li>
        <li className={`page-item`}>
          <button className="page-link" onClick={() => fetchTran(pages)}>
            {pages}
          </button>
        </li>
      </>
    );

    if (page > 5) {
      if (pages - page >= 5) {
        middlePagination = (
          <>
            <li className={`page-item`}>
              <button className="page-link" onClick={() => fetchTran(1)}>
                1
              </button>
            </li>
            <li className={`page-item`}>
              <button className="page-link" disabled>
                ...
              </button>
            </li>
            <li className={`page-item`}>
              <button className="page-link" onClick={() => fetchTran(startValue)}>
                {startValue}
              </button>
            </li>
            {[...Array(5)].map((_, idx) => (
              <li className={`page-item ${page === startValue + idx + 1 ? "active" : ""}`} key={startValue + idx + 1}>
                <button className="page-link" onClick={() => fetchTran(startValue + idx + 1)} disabled={page === startValue + idx + 1}>
                  {startValue + idx + 1}
                </button>
              </li>
            ))}

            <li className={`page-item`}>
              <button className="page-link" disabled>
                ...
              </button>
            </li>
            <li className={`page-item`}>
              <button className="page-link" onClick={() => fetchTran(pages)}>
                {pages}
              </button>
            </li>
          </>
        );
      } else {
        let amountLeft = pages - page + 5;
        middlePagination = (
          <>
            <li className={`page-item`}>
              <button className="page-link" onClick={() => fetchTran(1)}>
                1
              </button>
            </li>
            <li className={`page-item`}>
              <button className="page-link" disabled>
                ...
              </button>
            </li>
            <li className={`page-item`}>
              <button className="page-link" onClick={() => fetchTran(startValue)}>
                {startValue}
              </button>
            </li>
            {[...Array(amountLeft)].map((_, idx) => (
              <li className={`page-item ${page === startValue + idx + 1 ? "active" : ""}`} key={startValue + idx + 1} style={pages < startValue + idx + 1 ? { display: "none" } : null}>
                <button className="page-link" onClick={() => fetchTran(startValue + idx + 1)} disabled={page === startValue + idx + 1}>
                  {startValue + idx + 1}
                </button>
              </li>
            ))}
          </>
        );
      }
    }
  }

  return (
    pages > 1 && (
      <nav aria-label="Page navigation">
        <ul className="pagination justify-content-center mb-0">
          <li className="page-item">
            <button className="page-link" onClick={() => fetchTran(page - 1)} disabled={page === 1}>
              <span aria-hidden="true">« Prev</span>
            </button>
          </li>
          {pagination.pages &&
            [...Array(pagination.pages)].map((i, v) => {
              <li className={`page-item`} onClick={() => fetchTran(1)}>
                <button className="page-link">1</button>
              </li>;
            })}
          {middlePagination}
          <li className="page-item">
            <button className="page-link" onClick={() => fetchTran(page + 1)} disabled={page === pages}>
              <span aria-hidden="true">Next »</span>
            </button>
          </li>
        </ul>
      </nav>
    )
  );
}

export default Pagination;
