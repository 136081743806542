import React, { useEffect, useState } from 'react'
import { useParams } from "react-router-dom";
// import Pagination from '../../components/page/Pagination';

function Bids(props) {
  const { token } = props.data;
  const { id } = useParams();
  const [userBids, setUserBids] = useState({});
  const bitcoin = useState(29718.65);
  const [bidInfo, setBidInfo] = useState({});
  // const [pagination, setPagination] = useState("");

  const fetchBid = async () => {
    let response = await fetch(`${process.env.REACT_APP_API_HOST}/api/admin/user/${id}/bids`, {
      method: "Get",
      headers: { "Content-Type": "application/json", "auth-token": token },
    }).catch((error) => console.log(error));

    if (response.status === 401) {
      props.auth();
    } else {
      const json = await response.json();
      if (json.status === "success") {
        setUserBids(json.data.bids);
        // setPagination(json.data.pagination);
      }
    }
  };
  useEffect(() => {
    fetchBid(1);
    // eslint-disable-next-line
  }, []);

  const dateFormat = (date) => {
    let newDate = new Date(date);
    return newDate.toLocaleTimeString("en-US", { hour12: false });
  };

  const btcVal = (amt) => {
    var newBtc = amt / bitcoin;
    return newBtc > 0 ? newBtc.toFixed(5) : 0;
  };

  const handleBidInfo = async (bidId) => {
    let response = await fetch(`${process.env.REACT_APP_API_HOST}/api/admin/bid/${bidId}`, {
      method: "Get",
      headers: { "Content-Type": "application/json", "auth-token": token },
    }).catch((error) => console.log(error));

    if (response.status === 401) {
      props.auth();
    } else {
      const json = await response.json();
      if (json.status === "success") {
        // json.data.bid.btc_price = JSON.parse(json.data.bid.btc_price);
        setBidInfo(json.data);
      }
    }
  };

  const fullDate = (date) => {
    let newDate = new Date(date);
    return newDate.toLocaleDateString("en-US", { year: "numeric", month: "2-digit", day: "2-digit", hour: "2-digit", hour12: false, minute: "2-digit", second: "2-digit" });
  };

  return (
    <div className="app-content container center-layout mt-3">
      <div className="content-wrapper">
        <div className="content-body">
          <section className="mb-3 mt-1">
            <h4 className="gray font-large-1 headingBorder"><b>User Bids</b></h4>
            <div className="my-3">
              {userBids.length > 0 &&
                userBids.map((userBid) => {
                  return (
                    <div className="card round card-body mb-1" key={userBid._id}>
                      <div className="tableCard">
                        <div className="px-1 mb-1 mb-md-0">
                          <h4 className="font-weight-bold">Game</h4>
                          <h5 className="mb-0">{userBid.game.name}</h5>
                          <h5 className="mb-0">#{userBid.gid}</h5>
                        </div>
                        <div className="px-1 mb-1 mb-md-0">
                          <h4 className="font-weight-bold">Time</h4>
                          <h5 className="mb-0">{dateFormat(userBid.game.start_time)}</h5>
                          <h5 className="mb-0">{dateFormat(userBid.game.end_time)}</h5>
                        </div>
                        <div className="px-1 mb-1 mb-md-0">
                          <h4 className="font-weight-bold">Bid Amount</h4>
                          <h5 className="mb-0">₿{btcVal(userBid.amount)}</h5>
                          <h5 className="mb-0">${userBid.amount}</h5>
                        </div>
                        <div className="px-1 mb-1 mb-md-0">
                          <h4 className="font-weight-bold">Amount</h4>
                          {(userBid.status === "pending" || userBid.status === "running") && <h5>Result pending</h5>}
                          {(userBid.status === "win" || userBid.status === "sorry") && (
                            <>
                              <h5 className={`price mb-0 font-medium-2 ${userBid.status === "win" ? "success" : "danger"} font-weight-bold`}>₿{btcVal(userBid.status === "win" ? userBid.win_amount : 0)}</h5>
                              <h5 className={`price mb-0 font-medium-2 ${userBid.status === "win" ? "success" : "danger"} font-weight-bold`}>${userBid.status === "win" ? userBid.win_amount : 0}</h5>
                            </>
                          )}
                        </div>
                        <div className="px-1 mb-1 mb-md-0">
                          <h4 className="font-weight-bold">Wining Num</h4>
                          <h5 className="mb-0 text-center">{userBid.game.winning_number}</h5>
                        </div>
                        <div className="px-1 mb-1 mb-md-0">
                          <h4 className="font-weight-bold">Status</h4>
                          {(userBid.status === "pending" || userBid.status === "running") && <h5 className="mb-0 round badge badge-primary font-medium-2 ucfirst">{userBid.status}</h5>}
                          {(userBid.status === "win" || userBid.status === "sorry") && <h5 className={`mb-0 round badge badge-${userBid.status === "win" ? "success" : "warning"} font-medium-2 ucfirst`}>{userBid.status}</h5>}
                        </div>
                        <div className="px-1">
                          <h4 className="font-weight-bold">Action</h4>
                          <button className="btn btn-sm round btn-info font-medium-1" onClick={() => handleBidInfo(userBid._id)} data-toggle="modal" data-target="#bidInfoModal">More info</button>
                        </div>                  
                      </div>
                    </div>
                  );
                })
              }
              {!userBids.length && 
                <div className="card card-body round mb-1">
                  <h4 className="mb-0 font-larage-1 text-center">There is no data available !</h4>
                </div>
              }
            </div>
            {/* <div className="mb-3 mt-2">
              <Pagination data={{ pagination, fetchTran: fetchBid }} />
            </div>             */}
          </section>
          <div className="modal animated zoomIn" id="bidInfoModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content round">
                <div className="modal-header">
                  <h5 className="modal-title font-medium-3 font-weight-bold">Bid Info</h5>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">
                      <i className="fa-regular fa-rectangle-xmark"></i>
                    </span>
                  </button>
                </div>
                <div className="modal-body p-2">
                  <div className="table-responsive">
                    <table className="table mb-0 table-bordered">
                      <tbody className="font-medium-3">
                        <tr>
                          <td><b>Date Time</b></td>
                          <td>{fullDate(bidInfo?.bid?.created_at)}</td>
                        </tr>
                        <tr>
                          <td><b>Game Id</b></td>
                          <td>#{bidInfo?.bid?.gid}</td>
                        </tr>
                        <tr>
                          <td><b>Picked Number</b></td>
                          <td>
                            <span className="round badge badge-info">{bidInfo?.bid?.btc_range}</span>
                            {/* {bidInfo?.bid?.btc_price.length > 0 && 
                              bidInfo?.bid?.btc_price.map((picked, inx) => {
                                return <span key={inx+picked} className="round badge badge-info mr-1">{picked}</span>
                              })
                            } */}
                          </td>
                        </tr>
                        <tr>
                          <td><b>Status</b></td>
                          <td className="ucfirst">
                            {(bidInfo?.bid?.status === "pending" || bidInfo?.bid?.status === "running") && <h5 className="mb-0 round badge badge-primary font-medium-2 ucfirst">{bidInfo?.bid?.status}</h5>}
                            {(bidInfo?.bid?.status === "win" || bidInfo?.bid?.status === "sorry") && <h5 className={`mb-0 round badge badge-${bidInfo?.bid?.status === "win" ? "success" : "warning"} font-medium-2 ucfirst`}>{bidInfo?.bid?.status}</h5>}                          
                          </td>
                        </tr>
                        <tr>
                          <td><b>Winning Number</b></td>
                          <td><span className="badge badge-success font-medium-2 round">{bidInfo?.bid?.status === "win" || bidInfo?.bid?.status === "sorry" ? bidInfo?.game?.winning_number : ''}</span></td>
                        </tr>
                        <tr>
                          <td><b>Winning Amount</b></td>
                          <td><span className={`text-${bidInfo?.bid?.win_amount && 'success'}`}>${bidInfo?.bid?.win_amount}</span></td>
                        </tr>
                        <tr>
                          <td><b>Bid Units</b></td>
                          <td><span className="badge badge-info font-medium-2 round">{bidInfo?.bid?.unit}</span></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Bids
