import React, { useEffect, useState } from "react";

function Login(props) {
  const { setAdmin, setToken, setIsLogin } = props.data;
  const [user, setUser] = useState({ email: "", password: "" });
  useEffect(() => {
    let bodyTag = document.body;
    if (!bodyTag.classList.contains("1-column")) bodyTag.classList.add("1-column");
    bodyTag.dataset.col = "1-column";
    if (!bodyTag.classList.contains("bg-full-screen-image")) bodyTag.classList.add("bg-full-screen-image");
    if (!bodyTag.classList.contains("blank-page")) bodyTag.classList.add("blank-page");
    if (bodyTag.dataset.col === "2-column") bodyTag.dataset.col = "1-column";
    if (bodyTag.classList.contains("2-columns")) bodyTag.classList.remove("2-columns");
  }, []);

  const handleChange = (e, field) => {    
    let newUser = {...user};
    newUser[field] = e.target.value;    
    setUser(newUser);
  }

  const login = async () => {
    const email = document.getElementById('admin-email');
    const emailError = document.getElementById('admin-email-alert');
    const password = document.getElementById("user-pass");    
    const passwordError = document.getElementById("user-pass-alert");    

    if (!email.value) {
      email.classList.add('is-invalid');      
      emailError.classList.remove('d-none');
      emailError.innerHTML = 'Please enter your email id.';      
    }else{
      if(email.classList.contains("is-invalid")) email.classList.remove("is-invalid");
    }
    
    if (!password.value) {
      password.classList.add('is-invalid');      
      passwordError.classList.remove('d-none');
      passwordError.innerHTML = 'Please enter your password.';      
    }else{
      if(password.classList.contains("is-invalid")) password.classList.remove("is-invalid");
    }

    if(!email.value || !password.value) return false;

    let response = await fetch(`${process.env.REACT_APP_API_HOST}/api/admin/auth/login`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(user),
    });

    response = await response.json();
    if (response.status === "success") {
      localStorage.setItem("admin-token", response.data.authtoken);
      localStorage.setItem("admin", JSON.stringify(response.data.admin));
      setToken(response.data.authtoken);
      setAdmin(response.data.admin);
      setIsLogin(true);
    } else if (response.status === "failed") {
      document.getElementById("admin-email").classList.add("is-invalid");
      let emailInput = document.getElementById("admin-email-alert");
      emailInput.classList.remove("d-none");
      emailInput.innerHTML = response.error;
    }
  };

  return (
    <div className="app-content container center-layout mt-2">
      <div className="content-wrapper">
        <div className="content-body">
          <section className="row flexbox-container">
            <div className="col-12 d-flex align-items-center justify-content-center">
              <div className="col-lg-6 col-md-8 col-11 p-0">
                <div className="card border-grey border-lighten-3 px-1 py-1 m-0 round">
                  <div className="card-header border-0">
                    <div className="card-title text-center">
                      <img src={`${process.env.REACT_APP_HOST}/assets/images/logo/logo.png`} className="auth-logo" alt="branding logo"/>
                    </div>
                  </div>
                  <div className="card-content">
                    <p className="card-subtitle line-on-side text-muted text-center font-medium-3 mx-2 my-1">
                      <span>Login to Admin-Panel</span>
                    </p>
                    <p className="text-center font-medium-2 mt-2 mb-0 text-success d-none" id="alertSuccess"></p>
                    <div className="card-body">
                      <form className="form-horizontal" noValidate>
                        <fieldset className="form-group position-relative has-icon-left mb-1">
                          <input type="email" className="form-control font-medium-2" id="admin-email" value={user.email} placeholder="Enter mail id" onChange={(e) => handleChange(e, "email")} />
                          <div className="form-control-position"><i className="la la-envelope" style={{fontSize:"1.5rem"}}></i></div>
                          <div id="admin-email-alert" className="invalid-feedback d-none font-medium-1"></div>
                        </fieldset>
                        <fieldset className="form-group position-relative has-icon-left" id="userPass">
                          <input type="password" className="form-control font-medium-2" id="user-pass" value={user.password} placeholder="Enter password" onChange={(e) => handleChange(e, "password")}/>
                          <div className="form-control-position"><i className="la la-unlock" style={{fontSize:"1.5rem"}}></i></div>
                          <div id="user-pass-alert" className="invalid-feedback d-none font-medium-1"></div>
                        </fieldset>
                        <button type="button" id="loginBtn" className="btn btn-info btn-block font-medium-1" onClick={() => login()}><i className="la la-rocket" style={{fontSize:"1.5rem"}}></i> Login</button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default Login;
