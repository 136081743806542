import React from 'react'

function Footer() {
  return (
    <footer className="footer footer-transparent footer-light navbar-shadow">
      <p className="clearfix blue-grey lighten-2 text-sm-center mb-0 px-2 container center-layout">
        <span className="float-md-left d-block d-md-inline-block">
          Copyright &copy; 2023{" "} Play-Crypto
        </span>
        <span className="float-md-right d-none d-lg-block">
          Ver 1.0
          <span id="scroll-top"></span>
        </span>
      </p>
    </footer>
  )
}

export default Footer
